import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['quizTeen1', 'quizTeen2', 'quizTeen3', 'quizTeen4', 'quizTeen5'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paper = makeShortcode("Paper");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reflection"
    }}>{`Reflection`}</h1>
    <p>{`Have you ever found yourself using ‘accommodation’ when your teenager was anxious:`}</p>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen1" once={true} inline={true} question={<b>Because you felt anxious yourself?</b>} fallbackResponse={<p>
        Parents may identify (too) strongly with their teenager’s anxiety and distress, and become powerfully motivated
        to ‘rescue’ their teenager. Parents may want to provide their teenager with reassurance or a sense of security
        that they wished they had received. Or they may simply not know other ways to respond to anxiety! The rest of
        this module presents some useful strategies.
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen2" once={true} inline={true} question={<b>
        To prevent significant disruption to your teenager’s life, or the entire family (e.g. getting everyone ready for
        school and work)?
      </b>} fallbackResponse={<p>
        When it feels like you have to choose between accommodation and a significant disruption to loved ones’ (and
        your own) lives, accommodation may seem like the obvious choice! If the situation is a recurring one, it would
        be helpful to plan ahead how you can respond differently.
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen3" once={true} inline={true} question={<b>To protect your teenager’s social status (i.e. so that they don’t get judged negatively by others)?</b>} fallbackResponse={<p>
        Social status is really important for teenagers so it’s understandable that parents would want to help protect
        their teenagers from getting a hard time from others. A more helpful and lasting solution is to help your
        teenager develop their own social and anxiety-management skills – see the rest of this module and the{' '}
        <i>Good friends = supportive relationships</i> module for some tips.
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen4" once={true} inline={true} question={<b>Because you believe that anxiety is harmful and should be avoided?</b>} fallbackResponse={<p>
        It is no surprise that parents who hold this belief would accommodate their teenager’s anxiety. But remember,
        anxiety at low levels is normal and can be useful. The key is to manage it well, rather than avoid it – more on
        this later in this module.
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <Paper mdxType="Paper">
  <Quiz id="quizTeen5" once={true} inline={true} question={<b>
        Because your teenager reacted strongly against you not being accommodating (e.g. becoming physically or verbally
        aggressive, including accusations that you don’t care or understand how they feel)?
      </b>} fallbackResponse={<p>
        When parents do not accommodate their teenager’s anxiety, there is usually a short-term increase in their
        teenager’s distress. If your teenager has become dependent on you to accommodate their anxiety, they may find it
        too overwhelming to face anxieties without your ‘rescue’, and react strongly against you withdrawing your
        accommodation. The strategies we discuss in this module can help to not just replace accommodating behaviours,
        but also build your teenager’s ability to manage their own anxiety better.
      </p>} answers={[{
        name: 'Yes',
        correct: true
      }, {
        name: 'No',
        correct: false
      }]} mdxType="Quiz" />
    </Paper>
    <p>{`If you said ‘Yes’ to any of the above, take heart – you’re not alone! These
are the most common reasons why parents find themselves responding to their
teenagers’ anxiety with accommodation.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      